import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'

import './ParticipleTable.scss'

class ParticipleTable extends React.Component {
  static propTypes = {
    children: PropTypes.node
  }

  static skipped = false

  componentDidMount () {
    if (ParticipleTable.skipped) {
      this.props.history.replace('/participle/questions')
    }

  }

  render () {
    return (
      <div className='participle-table'>
        <h1>Vul eerst onderstaande schema's in:</h1>
        <div className='tables'>
          <table>
            <thead>
              <tr>
                <th>Hebben</th>
                <th>Tegenwoordige<br/>tijd</th>
                <th>Verleden<br/>tijd</th>
                <th>&nbsp;</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Ik</td>
                <td><input type='text'/></td>
                <td><input type='text'/></td>
                <td>een hond</td>
              </tr>
              <tr>
                <td>Jij</td>
                <td><input type='text'/></td>
                <td><input type='text'/></td>
                <td>een hond</td>
              </tr>
              <tr>
                <td>Hij/zij/het</td>
                <td><input type='text'/></td>
                <td><input type='text'/></td>
                <td>een hond</td>
              </tr>
              <tr>
                <td>Wij</td>
                <td><input defaultValue='hebben' type='text'/></td>
                <td><input defaultValue='hadden' type='text'/></td>
                <td>een hond</td>
              </tr>
              <tr>
                <td>Jullie</td>
                <td><input type='text'/></td>
                <td><input type='text'/></td>
                <td>een hond</td>
              </tr>
              <tr>
                <td>Zij</td>
                <td><input type='text'/></td>
                <td><input type='text'/></td>
                <td>een hond</td>
              </tr>
            </tbody>
          </table>
          <table>
            <thead>
              <tr>
                <th>Worden</th>
                <th>Tegenwoordige<br/>tijd</th>
                <th>Verleden<br/>tijd</th>
                <th>&nbsp;</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Ik</td>
                <td><input type='text'/></td>
                <td><input type='text'/></td>
                <td>kampioen</td>
              </tr>
              <tr>
                <td>Jij</td>
                <td><input type='text'/></td>
                <td><input type='text'/></td>
                <td>kampioen</td>
              </tr>
              <tr>
                <td>Hij/zij/het</td>
                <td><input type='text'/></td>
                <td><input type='text'/></td>
                <td>kampioen</td>
              </tr>
              <tr>
                <td>Wij</td>
                <td><input defaultValue='worden' type='text'/></td>
                <td><input defaultValue='werden' type='text'/></td>
                <td>kampioen</td>
              </tr>
              <tr>
                <td>Jullie</td>
                <td><input type='text'/></td>
                <td><input type='text'/></td>
                <td>kampioen</td>
              </tr>
              <tr>
                <td>Zij</td>
                <td><input type='text'/></td>
                <td><input type='text'/></td>
                <td>kampioen</td>
              </tr>
            </tbody>
          </table>
          <table>
            <thead>
              <tr>
                <th>Zijn</th>
                <th>Tegenwoordige<br/>tijd</th>
                <th>Verleden<br/>tijd</th>
                <th>&nbsp;</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Ik</td>
                <td><input type='text'/></td>
                <td><input type='text'/></td>
                <td>kampioen</td>
              </tr>
              <tr>
                <td>Jij</td>
                <td><input type='text'/></td>
                <td><input type='text'/></td>
                <td>kampioen</td>
              </tr>
              <tr>
                <td>Hij/zij/het</td>
                <td><input type='text'/></td>
                <td><input type='text'/></td>
                <td>kampioen</td>
              </tr>
              <tr>
                <td>Wij</td>
                <td><input defaultValue='zijn' type='text'/></td>
                <td><input defaultValue='waren' type='text'/></td>
                <td>kampioen</td>
              </tr>
              <tr>
                <td>Jullie</td>
                <td><input type='text'/></td>
                <td><input type='text'/></td>
                <td>kampioen</td>
              </tr>
              <tr>
                <td>Zij</td>
                <td><input type='text'/></td>
                <td><input type='text'/></td>
                <td>kampioen</td>
              </tr>
            </tbody>
          </table>
        </div>
        <button
          onClick={() => {
            ParticipleTable.skipped = true
            this.props.history.replace('/participle/questions')
          }}
          className='secondary'>Doorgaan
        </button>
      </div>
    )
  }
}

export default withRouter(ParticipleTable)
