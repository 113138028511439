import React from 'react'
import PropTypes from 'prop-types'

import './InfoPage.scss'

export default class InfoPage extends React.Component {
  static propTypes = {
    info: PropTypes.shape({
      title: PropTypes.string,
      description: PropTypes.string,
      file: PropTypes.string
    })
  }

  render () {
    const { title, description, file } = this.props.info

    return (
      <div className="info-page">
        <div className='info-page-center'>
          <h1>{title}</h1>
          <div dangerouslySetInnerHTML={{ __html: description }}/>

          <a className="button-button button-manual" target="_blank" href={file}>
            Download de handleiding
          </a>
        </div>
      </div>
    )
  }
}
