import React from 'react'
import PropTypes from 'prop-types'

import './LoginArea.scss'

export default class LoginArea extends React.Component {
  static propTypes = {
    loading: PropTypes.bool,
    onLogin: PropTypes.func
  }

  state = {
    code: ''
  }

  login (code) {
    if (code.length < 6) {
      alert('Voer een 6-cijferige code in om door te gaan!')
      return
    }

    this.props.onLogin(code)
  }

  render () {
    const { code } = this.state
    const { loading } = this.props

    return (
      <div className='login-area'>
        {loading ? (
          <div className='login-loader'>
            <div className='sk-folding-cube'>
              <div className='sk-cube1 sk-cube'/>
              <div className='sk-cube2 sk-cube'/>
              <div className='sk-cube4 sk-cube'/>
              <div className='sk-cube3 sk-cube'/>
            </div>
          </div>
        ) : (
          <div className='inner'>
            <div className='inner-inner'>
              <div className='login-container'>
                <img src={require('../assets/logo-login.svg')} alt='De Spelkist Online'/>
                <p>Welkom bij De Spelkist Online. <br/> Vul je code in om in te loggen.</p>
                <input
                  type='text'
                  placeholder='123456'
                  value={code}
                  onChange={(e) => this.setState({ code: e.target.value.replace(/[^\d]+/g, '').substring(0, 6) })}/>
                <button className='login-button' onClick={() => this.login(code)}>Login</button>
              </div>
              <div className='login-links'>
                <a href='https://despelkist.nl/' className='arrowed'>Meer informatie</a>
                <a href='mailto:info@despelkist.nl?subject=Code%20vergeten'>Code vergeten?</a>
              </div>
            </div>
          </div>
        )}
      </div>
    )
  }
}
