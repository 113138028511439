import React from 'react'
import PropTypes from 'prop-types'

export default class GrowingTextarea extends React.Component {
  static propTypes = {
    onChange: PropTypes.func,
    onKeyUp: PropTypes.func,
    value: PropTypes.string,
    ghostStyle: PropTypes.object,
    ghostRef: PropTypes.func,
    style: PropTypes.any,
    autoFocus: PropTypes.bool,
    horizontal: PropTypes.bool,
    vertical: PropTypes.bool
  }

  static defaultProps = {
    vertical: true,
    horizontal: false,
    autoFocus: false,
    style: {},
    ghostStyle: {}
  }

  constructor (props) {
    super(props)

    this.state = {
      height: 20,
      width: null,
      forceWidth: null,
      value: props.value || ''
    }

    this.setValue = this.setValue.bind(this)
    this.setFilledTextareaHeight = this.setFilledTextareaHeight.bind(this)
  }

  componentDidMount () {
    this.mounted = true

    this.setFilledTextareaHeight()
    setTimeout(() => this.setFilledTextareaHeight(), 100)
    setTimeout(() => this.setFilledTextareaHeight(), 300)
  }

  async componentWillReceiveProps (nextProps) {
    if ('value' in nextProps && nextProps.value !== this.state.value) {
      await this.setState({
        value: nextProps.value
      })
      this.setFilledTextareaHeight()
    }
  }

  setFilledTextareaHeight () {
    if (this.mounted && this.ghost) {
      const element = this.ghost

      if (this.props.vertical) {
        this.setState({
          height: element.clientHeight + 2
        })
      }
      if (this.props.horizontal) {
        this.setState({
          forceWidth: element.clientWidth + 20
        })
      }
    }
  }

  setValue (event) {
    const { value } = event.target

    this.setState({ value })

    this.props.onChange && this.props.onChange(event)
  }

  getExpandableField () {
    const isOneLine = this.state.height <= 20
    const { height, value, forceWidth } = this.state

    let { style, horizontal, vertical, ghostStyle, ...props } = this.props

    style = { ...style, resize: isOneLine ? 'none' : null }
    if (vertical && height) {
      style.height = height
    }
    if (horizontal && forceWidth) {
      style.width = forceWidth
      style.flexBasis = forceWidth
    }

    return (
      <textarea
        className='textarea'
        name='textarea'
        key='area'
        id='textarea'
        {...props}
        spellCheck={false}
        onChange={this.setValue}
        onKeyUp={async (event) => {
          await this.setState({ width: event.target.clientWidth })
          await (this.props.onKeyUp && this.props.onKeyUp(event))
          this.setFilledTextareaHeight()
        }}
        value={value}
        style={style}
      />
    )
  }

  getGhostField () {
    const style = { ...this.props.ghostStyle }
    if (this.props.horizontal) {
      style.width = 'auto'
      style.display = 'inline-block'
    } else if (this.state.width) {
      style.width = this.state.width
    }

    return (
      <div
        style={style}
        key='ghost'
        className={'textarea textarea--ghost'}
        ref={(c) => {
          this.ghost = c
          this.props.ghostRef && this.props.ghostRef(c)
        }}
        aria-hidden='true'
      >
        {(this.state.value || this.props.placeholder || '') + '+'}
      </div>
    )
  }

  render () {
    return (
      <>
        {this.getExpandableField()}
        {this.getGhostField()}
      </>
    )
  }
}
