import axios from 'axios'

export const loadData = async (loginCode) => {
  let url = `/data?code=${loginCode}`

  if (process.env.NODE_ENV === 'development') {
    console.log('Using development data source')
    url = '/_data_example.json'
  }

  const { data } = await axios.get(url)

  if (data && data.questions) {
    data.questions = data.questions.map((question) => {
      let tense = question.tense
      let category = question.tense
      if (tense.indexOf('|') > 0) {
        const tc = tense.split('|')
        category = tc[0]
        tense = tc[1]
      }

      return {
        ...question,
        tense,
        category
      }
    })
  }

  return data;
}
