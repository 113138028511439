import React from 'react'
import PropTypes from 'prop-types'
import { shuffle } from 'lodash'

import WorkArea from './WorkArea'

export default class GuidedMode extends React.Component {
  static propTypes = {
    tenses: PropTypes.arrayOf(PropTypes.string),
    category: PropTypes.string,
    data: PropTypes.object
  }

  state = {
    question: null,
    correct: undefined,
    previousQuestionIds: [],
    feedback: ''
  }

  componentDidMount () {
    if (!this.state.question) {
      this.pickQuestion()
    }
  }

  pickQuestion () {
    const { tenses, data, category } = this.props

    let newPrevQs = [...this.state.previousQuestionIds]
    let question = shuffle(data.questions.filter((question) =>
      newPrevQs.indexOf(question.id) < 0 &&
      question.category === category && tenses.indexOf(question.tense) >= 0
    ))[0]

    if (!question) {
      question = shuffle(data.questions.filter((question) => question.category === category && tenses.indexOf(question.tense) >= 0))[0];
      newPrevQs = []
    } else {
      newPrevQs.push(question.id)
    }

    this.setState({ question, correct: undefined, feedback: '', previousQuestionIds: newPrevQs })
  }

  change (answer, tense, force = false, verbRootValue) {
    const { question } = this.state
    const { data, showRoot } = this.props

    if (force) {
      if (answer === question.answer &&
        (!tense || tense === question.tense.replace('past-', '')) &&
        (showRoot || verbRootValue.trim().toLowerCase() === question.verbRoot.trim().toLowerCase())) {
        this.setState({ correct: true, feedback: '' });
      } else {
        let feedback = question.feedback || (tense && tense !== question.tense.replace('past-', '')
          ? 'Sorry, dat is niet de juiste tijd.'
          : data.general.feedback)
        this.setState({ correct: false, feedback })
      }
    }
  }

  render () {
    const { tenses, showRoot, onToggleShowRoot } = this.props
    const { question, correct, feedback } = this.state

    if (!question) {
      return (
        <div className='placeholder'>
          Oeps, we konden geen vragen vinden voor deze tijd...
        </div>
      )
    }

    let maxItems = 2
    if (tenses.length < 2 && (tenses[0] === 'present' || tenses[0] === 'past-participle' || tenses[0] === 'participle')) {
      maxItems = 1
    }

    let allowPrefix = question.tense === 'past-participle' || question.tense === 'participle'

    return (
      <WorkArea
        maxPostfixItems={maxItems}
        key={'q' + question.id}
        sentence={question.sentence}
        verbRoot={question.verbRoot}
        verbFull={question.verbFull}
        onChange={(answer, tense, force, verbRootValue) => this.change(answer, tense, force, verbRootValue)}
        onNext={() => this.pickQuestion()}
        correct={correct}
        tenses={tenses}
        showRoot={showRoot}
        onToggleShowRoot={onToggleShowRoot}
        allowPrefix={allowPrefix}
        feedback={feedback}
      />
    )
  }
}
