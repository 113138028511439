import React from 'react'
import PropTypes from 'prop-types'
import { Route, Switch } from 'react-router-dom'

import Navigation from '../../components/Navigation'
import { loadData } from '../../services/data'

import InfoPage from '../../components/InfoPage'
import LoginArea from '../../components/LoginArea'
import WorkArea from '../../components/WorkArea'
import GuidedMode from '../../components/GuidedMode'
import MobileCompat from '../../components/MobileCompat'
import ParticipleTable from '../../components/ParticipleTable'
import Quiz from '../../components/Quiz'

export default class Main extends React.Component {
  static propTypes = {
    children: PropTypes.node
  }

  state = {
    loading: false,
    data: null,
    index: 0
  }

  componentDidMount () {
    if (localStorage && localStorage['spelkist.v1.code'] && localStorage['spelkist.v1.code'].length === 6) {
      this.login(localStorage['spelkist.v1.code'], true)
    }
  }

  async login (code, silent = false) {
    this.setState({ loading: true })
    const data = await loadData(code);
    if (!data || data.error) {
      if (!silent) {
        alert(data ? data.error : 'Sorry, kon je niet inloggen...')
      }
      this.setState({ loading: false, data: null })
      if (localStorage) {
        localStorage['spelkist.v1.code'] = ''
      }
      return
    }

    this.setState({
      loading: false,
      data
    })

    if (localStorage) {
      localStorage['spelkist.v1.code'] = code
    }
  }

  wipe () {
    this.setState({ index: this.state.index + 1 })
  }

  render () {
    const { loading, data, showRoot } = this.state
    const onToggleShowRoot = () => this.setState({ showRoot: !showRoot });

    if (!data) {
      return (
        <>
          <MobileCompat/>
          <LoginArea
            onLogin={(code) => this.login(code)}
            loading={loading}/>
        </>
      )
    }

    return (
      <>
        <MobileCompat/>
        <Navigation/>
        <Switch>
          <Route
            path='/'
            exact
            render={() => (
              <WorkArea
                key={`freeform${this.state.index}`}
                freeform
								penMode={this.state.penMode}
								onPenMode={()=>this.setState({ penMode: !this.state.penMode })}
                onWipe={() => this.wipe()}
                maxPostfixItems={2}/>
            )}/>
          <Route
            path='/info'
            render={() => (
              <InfoPage info={data.info}/>
            )}/>
          <Route
            path='/present'
            render={() => (
              <GuidedMode key='present' onToggleShowRoot={onToggleShowRoot} showRoot={showRoot} data={data} category='present' tenses={['present']}/>
            )}/>
          <Route
            path='/past'
            render={() => (
              <GuidedMode key='past' onToggleShowRoot={onToggleShowRoot} showRoot={showRoot} data={data} category='past' tenses={['past']}/>
            )}/>
          <Route
            path='/present-past'
            render={() => (
              <GuidedMode key='present-past' onToggleShowRoot={onToggleShowRoot} showRoot={showRoot} data={data} category='ttvt' tenses={['present', 'past']}/>
            )}/>
          <Route
            path='/participle/questions'
            render={() => (
              <GuidedMode
                key='participle'
                onToggleShowRoot={onToggleShowRoot}
                showRoot={showRoot}
                data={data}
                category='past-participle'
                tenses={['past-participle']}/>
            )}/>
          <Route
            path='/participle'
            render={() => (
              <ParticipleTable/>
            )}/>
          <Route
            path='/everything'
            render={() => (
              <GuidedMode
                key='everything'
                onToggleShowRoot={onToggleShowRoot}
                showRoot={showRoot}
                data={data}
                category='all'
                tenses={['present', 'past', 'past-participle']}/>
            )}/>
          <Route
            path='/quiz'
            render={() => (
              <Quiz key='quiz' data={data}/>
            )}/>
        </Switch>
      </>
    )
  }
}
