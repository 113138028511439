import React from 'react'
import PropTypes from 'prop-types'
import { shuffle } from 'lodash'

import './Quiz.scss'

export default class Quiz extends React.Component {
  static propTypes = {
    data: PropTypes.object
  }

  state = {
    questions: [],
    index: 0,
    answer: '',
    modal: null
  }

  input = React.createRef()

  componentDidMount () {
    this.reset()
  }

  next () {
    const { index, questions, answer } = this.state

    questions[index].answered = true
    questions[index].userAnswer = answer.replace(/\s/g, '')
    questions[index].correct = answer.toLowerCase().replace(/\s/g, '') === questions[index].answer.toLowerCase().replace(/\s/g, '')

    this.setState({ questions, index: index + 1, answer: '' })
    this.input.current.focus()
  }

  reset () {
    const { data } = this.props

    const questions = shuffle([
      ...shuffle(data.questions.filter((q) => q.category === 'all' && q.tense === 'present')).slice(0, 10),
      ...shuffle(data.questions.filter((q) => q.category === 'all' && q.tense === 'past')).slice(0, 10),
      ...shuffle(data.questions.filter((q) => q.category === 'all' && q.tense === 'past-participle')).slice(0, 10)
    ])

    this.setState({ questions, answer: '', index: 0 })
  }

  renderModal () {
    const { questions, modal } = this.state
    if (!modal) {
      return null
    }

    const tenseTitles = {
      'past': 'verleden tijd',
      'prersent': 'tegenwoordige tijd',
      'past-participle': 'voltooid deelwoord'
    }

    const answers = questions.filter(q => q.tense === modal)
    const answersCount = answers.filter(q => q.correct).length

    return (
      <div className='modal'>
        <div className='modal-inner'>
          <a className='times' onClick={() => this.setState({ modal: null })}>&times;</a>
          <h2>Resultaten {tenseTitles[modal]} ({answersCount}/10 goed)</h2>
          <table>
            <thead>
              <tr>
                <th>Zin</th>
                <th>Jouw antwoord</th>
                <th>Goed antwoord</th>
              </tr>
            </thead>
            <tbody>
              {answers.map((question) => (
                <tr key={question.id}>
                  <td>{question.sentence}</td>
                  <td className={question.correct ? 'correct' : 'wrong'}>{question.userAnswer}</td>
                  <td className='correct'>{question.correct ? '' : question.answer}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    )
  }

  render () {
    const { index, questions, answer } = this.state
    if (!questions.length) {
      return null
    }

    if (index >= 30 || index >= questions.length) {
      const results = { 'past': 0, 'present': 0, 'past-participle': 0 }
      questions.forEach(question => {
        results[question.tense] += question.correct ? 1 : 0
      })

      const passedParts = Object.values(results).filter(x => x >= 8).length

      return (
        <main className='quiz' style={{ display: 'flex' }}>
          {this.renderModal()}
          <div className='quiz-inner'>
            <div className='quiz-results'>
              <h1>Resultaat toets</h1>
              <h2>
                {passedParts === 3 ? 'Yes! Alles gehaald!' :
                  (passedParts === 2 ? 'Nog iets meer oefening nodig.' :
                    'Helaas.. meerdere onderdelen niet gehaald')}
              </h2>
              <div className='quiz-graph'>
                <div className='quiz-legend'>
                  <div>100%</div>
                  <div>90%</div>
                  <div className='line'>80%</div>
                  <div>70%</div>
                  <div>60%</div>
                  <div>50%</div>
                  <div>40%</div>
                  <div>30%</div>
                  <div>20%</div>
                  <div>10%</div>
                  <div>0%</div>
                </div>
                <div
                  className={`quiz-res ${results.present >= 8 ? 'success' : 'fail'}`}
                  onClick={() => this.setState({ modal: 'present' })}>
                  <h3>Tegenwoordige tijd</h3>
                  <div className='bars'>
                    <div className='bar' style={{ height: (results.present * 10) + '%' }}>
                      <div/>
                    </div>
                  </div>
                </div>
                <div
                  className={`quiz-res ${results.past >= 8 ? 'success' : 'fail'}`}
                  onClick={() => this.setState({ modal: 'past' })}>
                  <h3>Verleden tijd</h3>
                  <div className='bars'>
                    <div className='bar' style={{ height: (results.past * 10) + '%' }}>
                      <div/>
                    </div>
                  </div>
                </div>
                <div
                  className={`quiz-res ${results['past-participle'] >= 8 ? 'success' : 'fail'}`}
                  onClick={() => this.setState({ modal: 'past-participle' })}>
                  <h3>Voltooid deelwoord</h3>
                  <div className='bars'>
                    <div className='bar' style={{ height: (results['past-participle'] * 10) + '%' }}>
                      <div/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <button className='try-again' onClick={() => this.reset()}>
              Probeer het opnieuw
            </button>
          </div>
        </main>
      )
    }

    const question = questions[index]
    const title = question.sentence.replace('...', `(${question.verbFull}) ...`)

    return (
      <main className='quiz' style={{ display: 'flex' }}>
        <div className='quiz-inner'>
          <div className='question'>{title}</div>
          <input
            type='text'
            ref={this.input}
            spellCheck={false}
            autoComplete='off'
            autoCapitalize='off'
            value={answer}
            onKeyUp={(e) => e.key === 'Enter' && answer && answer.length && this.next()}
            onChange={(e) => this.setState({ answer: e.target.value })}
            autoFocus
            placeholder='Typ de juiste vorm'/>
          <div className='progress'>{index + 1}/30</div>
          <button onClick={() => answer && answer.length && this.next()}>
            Volgende vraag &rarr;
          </button>
        </div>
      </main>
    )
  }
}
