import React from 'react'
import PropTypes from 'prop-types'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'

import GrowingTextarea from './GrowingTextarea'

import './WorkArea.scss'
import SignatureCanvas from 'react-signature-canvas'

export default class WorkArea extends React.Component {
  static propTypes = {
    freeform: PropTypes.bool,
    allowPrefix: PropTypes.bool,
    sentence: PropTypes.string,
    verbRoot: PropTypes.string,
    onChange: PropTypes.func,
    onWipe: PropTypes.func,
    onNext: PropTypes.func,
    correct: PropTypes.bool,
    feedback: PropTypes.string,
    tenses: PropTypes.arrayOf(PropTypes.string),
    maxPostfixItems: PropTypes.number,
    penMode: PropTypes.bool
  }

  state = {
    availableLettersPresent: [{
      id: 'present t',
      content: 't',
      list: 'availableLettersPresent',
      target: 'postfix'
    }],
    availableLettersPast: [{
      id: 'past n',
      content: 'n',
      list: 'availableLettersPast',
      target: 'postfix'
    }, {
      id: 'past te',
      content: 'te',
      list: 'availableLettersPast',
      target: 'postfix'
    }, {
      id: 'past de',
      content: 'de',
      list: 'availableLettersPast',
      target: 'postfix'
    }],
    availableLettersParticiple: [{
      id: 'participle d',
      content: 'd',
      list: 'availableLettersParticiple',
      target: 'postfix'
    }, {
      id: 'participle t',
      content: 't',
      list: 'availableLettersParticiple',
      target: 'postfix'
    }],
    availableLettersParticiplePrefix: [{
      id: 'prefix ge',
      content: 'ge',
      list: 'availableLettersParticiplePrefix',
      target: 'prefix'
    }, {
      id: 'prefix ver',
      content: 'ver',
      list: 'availableLettersParticiplePrefix',
      target: 'prefix',
      disabled: true
    }, {
      id: 'prefix be',
      content: 'be',
      list: 'availableLettersParticiplePrefix',
      target: 'prefix',
      disabled: true
    }],
    postfix: [],
    prefix: [],
    cardOpen: null,
    showRoot: false,
    verbRootValue: ''
  }

  async onDragEnd (result) {
    const { source, destination } = result

    if (!destination) {
      return // dropped outside the list
    }

    if (source.droppableId === destination.droppableId) {
      this.setState({
        [source.droppableId]: reorder(
          this.state[source.droppableId],
          source.index,
          destination.index
        )
      })
      return
    }

    const item = this.state[source.droppableId][source.index]
    if (destination.droppableId === 'postfix' && item.list === 'availableLettersParticiplePrefix') {
      return // can't drop prefix in postfix field
    }
    if (destination.droppableId === 'prefix' && item.list !== 'availableLettersParticiplePrefix') {
      return // can't drop postfix in prefix field
    }
    if (destination.droppableId.indexOf('available') === 0 && destination.droppableId !== item.list) {
      return // can't drop into other bucket
    }

    const dest = this.state[destination.droppableId]
    if (destination.droppableId === 'postfix') {
      if (dest.length > 0 && item.content !== 'n') {
        if (dest[0].content === 'n') {
          destination.index = 0
        } else {
          return // only two items allowed are de-n and te-n
        }
      }
      if (item.content === 'n') {
        destination.index = 1
      }
    }

    await this.setState(move(
      this.state[source.droppableId],
      dest,
      source,
      destination
    ))

    this.updateAnswer()
  }

  updateAnswer (force = false) {
    const answer = this.state.prefix.map(letter => letter.content).join('') +
      this.props.verbRoot + this.state.postfix.map(letter => letter.content).join('')

    let tense = ''
    this.state.postfix.forEach(letter => {
      tense = letter.id.split(' ')[0]
    })

    this.props.onChange && this.props.onChange(answer, tense, force, this.state.verbRootValue)
  }

  clickLetter (item, index, inTargetList = false) {
    if (inTargetList) {
      this.onDragEnd({
        source: { index, droppableId: item.target },
        destination: { index: this.state[item.list].length, droppableId: item.list }
      })
    } else {
      this.onDragEnd({
        source: { index, droppableId: item.list },
        destination: { index: this.state[item.target].length, droppableId: item.target }
      })
    }
  }

  renderLetter (item, index, inTargetList, disabled = false) {
    return (
      <Draggable
        key={item.id}
        draggableId={item.id}
        isDragDisabled={disabled}
        index={index}>
        {(provided, snapshot) => (
          <div
            className={`letter ${item.id} ${disabled ? 'disabled' : ''}`} ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            onClick={() => !disabled && this.clickLetter(item, index, inTargetList)}
            style={getItemStyle(snapshot.isDragging, provided.draggableProps.style, inTargetList)}>
            {item.content}
          </div>
        )}
      </Draggable>
    )
  }

  render () {
    const { freeform, sentence, verbRoot, showRoot, onToggleShowRoot, verbFull, correct, feedback, onNext, tenses, onWipe, penMode, onPenMode, allowPrefix } = this.props
    const { cardOpen, verbRootValue } = this.state

    return (
      <DragDropContext onDragEnd={(result) => this.onDragEnd(result)}>
        <main style={{ display: 'flex' }}>
          <ol className='legend'>
            <li>
              <span className='green'>groen</span>, <span className='blue'>blauw</span> of <span className='red'>rood</span>
            </li>
            <li>ik-vorm opschrijven</li>
            <li>kaartje aanleggen</li>
          </ol>
          <div className='cards'>
            <div className={'card present' + (cardOpen === 'present' ? ' open' : '') + (tenses && tenses.length && tenses.indexOf('present') < 0 ? ' card-disabled' : '')}>
              <div className='front'>
                <a className='card-info' onClick={() => this.setState({ cardOpen: 'present' })}>i</a>
                <h3>Tegenwoordige tijd</h3>
                <Droppable droppableId='availableLettersPresent'>
                  {(provided) => (
                    <div ref={provided.innerRef} className='inner' {...provided.droppableProps}>
                      {this.state.availableLettersPresent.map((item, index) => this.renderLetter(item, index))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </div>
              <div className='back'>
                <a
                  className='card-close'
                  onClick={() => this.setState({ cardOpen: null })}>&times;</a>
                <h3 className="green">Tegenwoordige tijd</h3>
                <div className='back-content'>
                  <div className='back-content-inner'>
                    <div className="rule">
                      <div>Spelregel 1</div>
                      <div>
                        <p className="ex">
                          Hij word<strong>t</strong>
                        </p>
                        <p>want</p>
                        <p className="ex">
                          Hij loop<strong>t</strong>
                        </p>
                        <p>bij wij, jullie en zij (meervoud) gebeurt niets bijzonders</p>
                      </div>
                    </div>
                    <div className="rule">
                      <div>Spelregel 2</div>
                      <div>
                        <p className="ex">
                          Hij wacht
                          <del>t</del>
                        </p>
                        <p>geen enkel woord eindigt op tt</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={'card past' + (cardOpen === 'past' ? ' open' : '') + (tenses && tenses.length && tenses.indexOf('past') < 0 ? ' card-disabled' : '')}>
              <div className='front'>
                <a
                  className='card-info'
                  onClick={() => this.setState({ cardOpen: 'past' })}>i</a>
                <h3>Verleden tijd</h3>
                <Droppable droppableId='availableLettersPast'>
                  {(provided) => (
                    <div ref={provided.innerRef} className='inner' {...provided.droppableProps}>
                      {this.state.availableLettersPast.map((item, index) => this.renderLetter(item, index))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </div>
              <div className='back'>
                <a className='card-close' onClick={() => this.setState({ cardOpen: null })}>&times;</a>
                <h3 className="blue">Verleden tijd</h3>
                <div className='back-content'>
                  <div className='back-content-inner'>
                    <div className="rule">
                      <div>Spelregel 1</div>
                      <div>
                        <p className="ex">
                          verhui<strong>z</strong>en
                        </p>
                        <p>
                          zit de laatste letter in 't kofschip X: <strong>te(n)</strong><br/>
                          anders: <strong>de(n)</strong><br/>
                        </p>
                      </div>
                    </div>
                    <div className="rule">
                      <div>Spelregel 2</div>
                      <div>
                        <p className="ex">
                          ik zw<strong>e</strong>m → ik zw<strong>o</strong>m
                        </p>
                        <p>sterke werkwoorden krijgen geen achtervoegsel</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={'card participle' + (cardOpen === 'participle' ? ' open' : '') + (tenses && tenses.length && tenses.indexOf('past-participle') < 0 ? ' card-disabled' : '')}>
              <div className='front'>
                <a className='card-info' onClick={() => this.setState({ cardOpen: 'participle' })}>i</a>
                <h3>Voltooid deelwoord</h3>
                <Droppable droppableId='availableLettersParticiplePrefix'>
                  {(provided) => (
                    <div ref={provided.innerRef} className='prefixes' {...provided.droppableProps}>
                      {this.state.availableLettersParticiplePrefix.map((item, index) => this.renderLetter(item, index, null, !allowPrefix && !freeform))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
                <Droppable droppableId='availableLettersParticiple'>
                  {(provided) => (
                    <div ref={provided.innerRef} className='inner' {...provided.droppableProps}>
                      {this.state.availableLettersParticiple.map((item, index) => this.renderLetter(item, index))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </div>
              <div className='back'>
                <a className='card-close' onClick={() => this.setState({ cardOpen: null })}>&times;</a>
                <h3 className="red">Voltooid deelwoord</h3>
                <div className='back-content'>
                  <div className='back-content-inner'>
                    <div className="rule">
                      <div>Spelregel 1</div>
                      <div>
                        <p className="ex">
                          hebben<br/>zijn<br/>worden
                        </p>
                        <p>
                          zit er een hulpwerkwoord in de zin?
                        </p>
                      </div>
                    </div>
                    <div className="rule">
                      <div>Spelregel 2</div>
                      <div>
                        <p>ga terug naar de verleden tijd</p>
                        <p className="ex">
                          wissel <strong>te</strong> voor <del>t</del><br/>
                          wissel <strong>de</strong> voor <del>d</del>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={`work-area ${freeform && penMode ? 'pen' : ''}`}>
            {freeform && penMode ? (
              <SignatureCanvas
                key={'signa'}
                ref={() => {
                  const canvas = document.getElementById('signature-pad')
                  if (!canvas || canvas.className.indexOf('loaded') >= 0) {
                    return
                  }
                  const ratio = Math.max(window.devicePixelRatio || 1, 1)
                  canvas.width = canvas.offsetWidth * ratio
                  canvas.height = canvas.offsetHeight * ratio
                  canvas.className += ' loaded'
                  canvas.getContext('2d').scale(ratio, ratio)
                }}
                minWidth={2}
                maxWidth={2}
                penColor='white'
                velocityFilterWeight={0}
                canvasProps={{ width: '100%', height: '100%', id: 'signature-pad' }}/>
            ) : null}

            <div className='work-area-inner'>
              <div className={`sentence ${freeform ? 'editable' : ''}`}>
                {freeform ? (
                  <GrowingTextarea placeholder='Voorbeeldzin...'/>
                ) : (`${sentence}${showRoot ? '' : ` (${verbFull})`}`)}
              </div>

              <div className='answer'>
                <div className='line'>
                  <span className='scribble'/>
                  {(allowPrefix || freeform) && !penMode ? (
                    <Droppable droppableId='prefix'>
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          className='letters-target prefix-target'
                          style={{ minWidth: 120 }}
                          {...provided.droppableProps}>
                          {this.state.prefix.map((item, index) => this.renderLetter(item, index, true))}
                          {provided.placeholder}
                          {this.state.postfix.length < this.props.maxPostfixItems ? (
                            <div className='letter-slot'/>
                          ) : null}
                        </div>
                      )}
                    </Droppable>
                  ) : null}
                  <div className={`verb-root ${freeform || !showRoot ? 'editable' : ''}`}>
                    {freeform ? (
                      <GrowingTextarea vertical={false} horizontal placeholder='werkwoord'/>
                    ) : (showRoot ? (
                      verbRoot
                    ) : (
                      <GrowingTextarea
                        value={verbRootValue}
                        onChange={(e) => this.setState({ verbRootValue: e.target.value })}
                        vertical={false} horizontal placeholder='ik-vorm'/>
                    ))}
                  </div>
                  <Droppable droppableId='postfix'>
                    {(provided) => (
                      <div
                        ref={provided.innerRef}
                        className='letters-target'
                        style={{ minWidth: this.state.postfix.length > 0 && this.props.maxPostfixItems > 1 ? 240 : 120 }}
                        {...provided.droppableProps}>
                        {this.state.postfix.map((item, index) => this.renderLetter(item, index, true))}
                        {provided.placeholder}
                        {this.state.postfix.length < this.props.maxPostfixItems ? (
                          <div className='letter-slot'/>
                        ) : null}
                      </div>
                    )}
                  </Droppable>
                </div>
              </div>

              <div className='feedback'>
                {correct !== undefined ? (
                  <>
                    <a
                      onClick={() => onNext()}
                      className={`feedback-next ${correct ? 'correct' : 'incorrect'}`}>
                      <span>{correct ? 'GOED!' : 'HELAAS FOUT!'}</span>
                      VOLGENDE VRAAG
                    </a>
                    {feedback ? (<p>{feedback}</p>) : null}
                  </>
                ) : (freeform ? null : (
                  <a
                    onClick={() => this.updateAnswer(true)}
                    className={`feedback-next`}>Controleer</a>
                ))}
              </div>
            </div>
            {freeform ? (
              <button
                className={`pen-mode ${penMode ? 'active' : ''}`}
                onClick={() => onPenMode()}>
                Pen-modus
              </button>
            ) : (
              <button
                className='root-mode'
                onClick={() => onToggleShowRoot()}>
                {showRoot ? '_' : 'T'}
              </button>
            )}
            {onWipe ? (
              <button
                className={`wipe`}
                onClick={() => onWipe()}>
                WISSEN
              </button>
            ) : null}
          </div>
        </main>
      </DragDropContext>
    )
  }
}

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

const move = (source, destination, droppableSource, droppableDestination) => {
  const sourceClone = Array.from(source)
  const destClone = Array.from(destination)
  const [removed] = sourceClone.splice(droppableSource.index, 1)

  destClone.splice(droppableDestination.index, 0, removed)

  const result = {}
  result[droppableSource.droppableId] = sourceClone
  result[droppableDestination.droppableId] = destClone

  return result
}

const getItemStyle = (isDragging, dragStyle, inTargetList = false) => {
  const style = { ...dragStyle }
  if (isDragging) {
    style.marginTop = 0
    style.marginBottom = 0
    style.marginLeft = 0
    style.marginRight = inTargetList ? 12 : 0
  }

  return style
}
