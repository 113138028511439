import React from 'react'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'

import './Navigation.scss'

export default class Navigation extends React.Component {
  static propTypes = {
    children: PropTypes.node
  }

  render () {
    return (
      <nav>
        <ul className='tabs'>
          <li className='logo'><NavLink to='/info'><span>Spelkist</span></NavLink></li>
          <li><NavLink exact to='/'><span>Vrij oefenen</span></NavLink></li>
          <li><NavLink to='/present'><span>Tegenwoordige tijd</span></NavLink></li>
          <li><NavLink to='/past'><span>Verleden tijd</span></NavLink></li>
          <li><NavLink to='/present-past'><span>T.T. &amp; V.T.</span></NavLink></li>
          <li><NavLink to='/participle'><span>Voltooid deelwoord</span></NavLink></li>
          <li><NavLink to='/everything'><span>Alles</span></NavLink></li>
          <li><NavLink to='/quiz'><span>Toets</span></NavLink></li>
        </ul>
      </nav>
    )
  }
}
